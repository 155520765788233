<template>
<div class="profile" :class="{'pa-16' : $vuetify.breakpoint.smAndUp }">
    <v-container class="zindex-max" :class="[$vuetify.breakpoint.smAndDown ? 'p-fixed top20 left5' : '', isMenu ? 'visibility-hidden': '']">
        <v-row>
            <v-col cols="12">
                <v-btn text class="pa-1" @click="back()">
                    <v-icon>mdi-chevron-left</v-icon>
                    Back
                </v-btn>
            </v-col>
        </v-row>
    </v-container>

    <meo-ripple :config="config" @clicked="clicked" v-if="$vuetify.breakpoint.smAndDown"></meo-ripple>

    <transition name="slide-y-transition" mode="out-in" appear>
        <router-view :sections="sections" :key="cache" />
    </transition>

    <input type="file" ref="file" @change="fileSelected($event.target.files)" class="d-none" accept="image/*">
</div>
</template>

<script>
// Mixins
import UserMixin from '@/mixins/user-mixin';
import SEOMixin from '@/mixins/seo-mixin';

// Components
import MeoRipple from "@/components/misc/meo-ripple.vue";

// Packages
import _ from "lodash";

//services
import Contact from "@/modules/meo-module/contact";
import ProfileArticle from "@/modules/meo-module/profile-article";


export default {
    name: 'profile',

    mixins: [UserMixin, SEOMixin],

    props: {},

    components: {
        MeoRipple
    },



    data() {
        return {
            date: new Date().getTime(),
            progress: 0,
            bytesLoaded: 0,
            bytesTotal: 0,
            session: {},
            cache: 0,
            config: {
                image: "",
                avatar: true
            },
            articles: [],
            // sections: [{
            //     title: "My Favourites",
            //     icon: "neu-love",
            //     name: "profile.favourites"
            // }, {
            //     title: "My Progress",
            //     icon: "neu-statistic-board",
            //     name: "profile.progress"
            // }, {
            //     title: "My Details",
            //     icon: "neu-user-information",
            //     name: "profile.details"
            // }, {
            //     title: "Terms & Conditions",
            //     icon: true,
            //     image: require("@/assets/terms.png"),
            //     name: "profile.terms"
            // }, {
            //     title: "Privacy Policy",
            //     icon: true,
            //     image: require("@/assets/terms.png"),
            //     name: "profile.privacy"
            // }, {
            //     title: "Logout",
            //     icon: "neu-logout-bracket",
            //     dark: true,
            //     name: "logout"
            // }]
        };
    },

    computed: {
        isMenu() {
            return this.$route.name == 'profile.menu';
        },
        sections() {
            var sections = [];

            sections.push({
                title: "My Favourites",
                icon: "neu-love",
                name: "profile.favourites"
            }, {
                title: "My Progress",
                icon: "neu-statistic-board",
                name: "profile.progress"
            }, {
                title: "My Details",
                icon: "neu-user-information",
                name: "profile.details"
            });

            if (this.articles && this.articles.length) {
                this.articles.forEach(article => {
                    sections.push({
                        title: article.title,
                        icon: true,
                        image: this.$fluro.asset.imageUrl(article.data.image),
                        name: 'profile.article',
                        id: article._id
                    });
                });
            }

            sections.push({
                title: "Logout",
                icon: "neu-logout-bracket",
                dark: true,
                name: "logout"
            });

            return sections;
        }
    },

    methods: {
        async init() {
            this.$meo.loading = true;

            this.session = await this.$fluro.api.get('/session', { cache: false }).then(({ data }) => data);
            this.articles = await ProfileArticle.list().then(({ data }) => data);
            let avatar = await this.$fluro.asset.avatarUrl(this.session && this.session.contacts[0], 'contact', { forceRefresh: true, date: this.date });

            this.config.image = avatar;

            this.$meo.loading = false;
        },
        clicked() {
            if (!this.$meo.loading) this.$refs.file.click();
        },
        fileSelected(files) {
            var self = this;
            self.$meo.loading = true;

            var file = _.first(files);

            console.log('file selected!', file)

            ///////////////////////////////////////

            var formData = new FormData();
            formData.append('file', file, 'blob.jpg')

            ///////////////////////////////////////

            var config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {

                    let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                    // do whatever you like with the percentage complete
                    // maybe dispatch an action that will update a progress bar or something
                    self.progress = percentCompleted;
                    self.bytesLoaded = progressEvent.loaded;
                    self.bytesTotal = progressEvent.total;

                    ///////////////////////////////////////////////////

                    self.$forceUpdate();
                }
            };

            ///////////////////////////////////////

            console.log("about to post MObile", config, formData);
            return self.$fluro.api.post(`/contact/${self.session && self.session.contacts[0]}/image`, formData, config)
                .then(function({ data }) {
                    self.date = (new Date).getTime()
                    self.init();
                })
                .catch(function(err) {
                    self.reset();
                });
        },
        reset() {
            this.$meo.loading = false;
            this.progress = 0;
            this.bytesLoaded = 0;
            this.bytesTotal = 0;
        },
        back() {
            this.$router.go(-1);
        }
    },

    async mounted() {
        await this.init();
    }
}
</script>

<style lang="scss">
</style>
